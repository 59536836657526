import _u from 'wtc-utility-helpers';

let url_language,
    body = document.body,
    docroot = '/',
    tldn = 'https://supermariomaker.nintendo.com',
    CONFIG = {},
    uA = navigator.userAgent;

// Get the document language
if (_u.hasClass('lang-es', body)) {
  url_language = 'es';
} else if (_u.hasClass('lang-fr', body)) {
  url_language = 'fr';
} else {
  url_language = 'en';
}

let isIE10, isEdge, isWiiU, isIos, iosVersion, isAndroid, isSafari, is_chrome, is_safari = false;

body.setAttribute('data-useragent', uA);

isIE10 = RegExp('msie' + ('\\s10'), 'i').test(uA);
if (isIE10) {
  _u.addClass('is-ie10', body);
}

isEdge = /Edge\//.test(uA);
if (isEdge) {
  _u.addClass('is-edge', body);
}

isWiiU = /WiiU\//.test(uA);
if (isWiiU) {
  _u.addClass('is-wiiu', body);
}

isIos = /iP(hone|od|ad)/.test(uA);
if (isIos) {
  _u.addClass('is-ios', body);

  // get ios version
  // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
  let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
  iosVersion = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)][0];
}

isAndroid = /Android/.test(uA);
if (isAndroid) {
  _u.addClass('is-android', body);
}

// Class for nasty hacks
is_chrome = uA.indexOf('Chrome') > -1;
is_safari = uA.indexOf("Safari") > -1;
if (is_safari && !is_chrome) {
  isSafari = true;
  _u.addClass('is-safari-only', body);
}

CONFIG.CONST = {
  ENV: 'DEPLOY',
  ISIE10: isIE10,
  ISEDGE: isEdge,
  ISWIIU: isWiiU,
  ISIOS: isIos,
  IOSVERSION: iosVersion,
  ISANDROID: isAndroid,
  ISSAFARI: isSafari,
  DOCROOT: docroot,
  ASSETS: docroot + 'assets/',
  LANGUAGE: url_language,
  VIDEOS: {
  },
  VIDEOS_R: {},
  PLATFORM: "switch",
  SUITE: {
    EN: "ncomglobal",
    FR: "ncomglobal",
    ES: "ncomglobal"
  },
  CONTENTTYPE: 'microsite:game', // prop2
  SITENAME: "super mario maker 2", // prop1
  PRODUCTNAME: "super mario maker 2", // prop10
  GENRE: "",
  PUBLISHER: "",
  PRODUCTCODE: "",
  PRODUCTID: "[product id - aka. retailer id]" // prop7
};

CONFIG.CONST.DEVMODE = (CONFIG.CONST.ENV === 'DEV' || CONFIG.CONST.ENV === 'LOCAL') ? true : false;

// Set up all our tracking
let pagename = body.getAttribute('data-page-omniture') || null;
let ishome = body.getAttribute('data-page') == 'home' ? true : false;
let referrer = /(\?|\&)r=([a-zA-Z0-9\.]+)/.exec(window.location.href);
let locale = "us:en";

if (url_language === "fr") {
  locale = "fr:ca";
}
else if(url_language === 'es') {
  locale = "mx:es";
}

if (referrer && referrer.length === 3) {
  referrer = referrer[2];
} else {
  referrer = null;
}

if (CONFIG.CONST.ENV.toUpperCase() !== 'DEPLOY') {
  CONFIG.CONST.SUITE = {
    EN: "ncomdev,ncomdev",
    FR: "ncomdev,ncomdev",
    ES: "ncomdev,ncomdev"
  };
}

CONFIG.CONST.TRACKEROPTS = {
  devMode: CONFIG.CONST.DEVMODE,
  trackerProps: {
    suite: CONFIG.CONST.SUITE[url_language.toUpperCase()],
    siteName: CONFIG.CONST.SITENAME,
    pageName: pagename,
    platform: CONFIG.CONST.PLATFORM,
  },
  pageTrackOpts: {
    locale: locale,
    vanity: referrer,
    contentType: CONFIG.CONST.CONTENTTYPE,
    productCode: CONFIG.CONST.PRODUCTCODE,
    productName: CONFIG.CONST.PRODUCTNAME,
    productId: CONFIG.CONST.PRODUCTID,
    gameTitle: CONFIG.CONST.PRODUCTNAME,
    genre: CONFIG.CONST.GENRE,
    publisher: CONFIG.CONST.PUBLISHER,
    isHome: ishome
  }
};

export default CONFIG;
