import { ncloodLoaded, getUser } from "./nclood";

// How this component works:

// The state is maintained in the HTMLElement itself, in the dataset,
// and that will be the source of truth for all state-related calls here.

// <your-element data-added="true">
// <your-element data-added="false">

// The API queries will update the dataset as needed.

let user = null;

/**
 * Toggles the UI, and only the UI; meaning this does not make
 * any API calls.
 *
 * @param {HTMLElement} element - the trigger button to manipulate
 * @param {Boolean} addedState - whether the item should be added or removed
 */
const toggleUI = function (element, addedState) {
  const text = element.querySelector(".wishlist-button__text");

  // swap "pressed" attribute, which animates the icon
  element.setAttribute("aria-pressed", addedState ? "true" : "false");

  // swap UI text
  if (text) {
    const { removeText, addText } = element.dataset;
    text.innerHTML = addedState ? removeText : addText;
  }

  // update the "added" variable
  if (addedState) element.dataset.added = "true";
  else element.dataset.added = "false";
};

/**
 * Adds or removes an item from the logged-in user's wishlist
 *
 * @param {HTMLElement} element - the trigger button or other UI element
 */
const updateWishlist = async (element) => {
  // First, update our added state based on what the element tells us
  const oldState = element.dataset.added === "true" ? true : false;
  const newState = !oldState;
  const { nsuid } = element.dataset;

  // Then, cosmetically update the element to give the ILLUSION OF SPEED!
  toggleUI(element, newState);

  // Finally, query the API...
  try {
    // If it's successful, great. We are done.
    await window.nclood.Wishlist[newState ? "add" : "remove"]({
      category: nclood.Wishlist.Category.ESHOP_PRODUCT,
      id: nsuid,
    });
  } catch (err) {
    // If it is not successful, revert the UI.
    toggleUI(element, oldState);
    console.log(err);
    // TODO: add an error state?
  }
};

/**
 * Gets the logged-in user's Wish List
 *
 * @returns {Array} the wish list
 */
const getWishlist = async () => {
  try {
    const wishlist = await window.nclood.Wishlist.getAll();
    // console.log(wishlist);
    return wishlist;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Handles click behavior and delegates actions based on whether
 * or not the user is logged in.
 *
 * @param {MouseEvent} event
 */
const handleClick = async (event) => {
  // FYI: targeting the element here with event.target.closest()
  // ended up being a very specific need, due to nintendo analytics
  // seemingly overriding the MouseEvent type and making it a
  // PointerEvent (which doesn't have currentTarget):
  const element = event.target.closest("button");

  if (user) {
    updateWishlist(element);
  } else {
    await nclood.Accounts.promptLogin();
    user = await getUser();
    // onLogin will run automatically here, to initialize the buttons.
  }
};

/**
 * Callback for nclood.Accounts.onLogin()
 */
const onLogin = async () => {
  let wishlist = await getWishlist();
  initializeButtons(wishlist);
};

/**
 * Callback for nclood.Accounts.onLogout()
 */
const onLogout = () => {
  user = null;
  initializeButtons(null);
};

/**
 * This sets the buttons' initial states, and only runs once per
 * page load, and once per login.
 *
 * @param {Array} wishlist - Wish List array as received from the API
 * Pass a falsy value here to "reset" behavior for logged out users.
 */
const initializeButtons = (wishlist) => {
  const buttons = document.querySelectorAll(".wishlist-button[data-nsuid]");

  if (buttons && buttons.length) {
    for (let b of buttons) {
      const { removeText, addText, nsuid } = b.dataset;
      const textElement = b.querySelector(".wishlist-button__text");
      const exists = wishlist
        ? wishlist.some((item) => item.id === nsuid)
        : false;

      // Add all the necessary aria attributes
      b.dataset.added = exists ? "true" : "false";
      b.setAttribute("aria-pressed", exists ? "true" : "false");
      b.querySelector(".wishlist-button__text").setAttribute(
        "aria-live",
        "polite"
      );

      // Update the text
      textElement.innerHTML = exists ? removeText : addText;

      // Hook up the toggle events, but clean up any existing ones:
      b.removeEventListener("click", handleClick);
      b.addEventListener("click", handleClick);
    }
  }
};

/**
 * Initializes the wishlist button behavior
 */
const initWishlist = async () => {
  try {
    await ncloodLoaded();
    user = await getUser();

    if (user) onLogin();
    else onLogout();

    nclood.Accounts.onLogin(onLogin);
    nclood.Accounts.onLogout(onLogout);
  } catch (err) {
    console.log(err);
  }
};

export default initWishlist;
