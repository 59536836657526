import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Breakpoints from 'wtc-utility-breakpoint';

class Play extends ElementController {
  constructor (element) {
    super(element);

    const pattern = new Image();
    
    this.patternContainer = element.querySelector('.cheep-cheeps__pattern');

    this.onPatternLoaded = this.onPatternLoaded.bind(this);
    this.onResize = this.onResize.bind(this);

    pattern.addEventListener('load', this.onPatternLoaded);
    window.addEventListener('resize', this.onResize);

    pattern.src = '../assets/img/play/pattern-cheep-cheeps.png';
  }

  adjustContainer() {
    let remainder = (window.innerWidth * 2) % this.patternWidth,
      difference = 0,
      newWidth;

    if (remainder) {
      difference = this.patternWidth - remainder;
    }

    newWidth = window.innerWidth * 2 + difference;
    this.patternContainerWidth = newWidth;
    this.patternContainer.style.width = `${this.patternContainerWidth}px`;
  }

  onPatternLoaded(e) {
    this.patternWidth = e.currentTarget.naturalWidth;
    this.adjustContainer();
  }

  onResize() {
    this.adjustContainer();
  }

  get patternWidth() {
    return this._patternWidth;
  }

  set patternWidth(value) {
    this._patternWidth = value;
  }

  get patternContainerWidth() {
    return this._patternContainerWidth;
  }

  set patternContainerWidth(value) {
    this._patternContainerWidth = value;
  }
}

ExecuteControllers.registerController(Play, 'Play');

export default Play;
