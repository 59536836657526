import _u from 'wtc-utility-helpers';
import Modal from 'wtc-modal-view';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import CONFIG from '../config';

class RetailerButton extends ElementController {
  constructor (element) {
    super(element);

    this.platform = this.element.getAttribute('data-platform');
    this.country  = this.element.getAttribute('data-country');
    this.productId = this.element.getAttribute('data-productid');
    this.hide = this.element.getAttribute('data-hideretailer');

    this.element.addEventListener('click', (e) => {
      // e.preventDefault();
      this.open();
    });
  }

  open () {
    let modal = Modal.instance;
    let panel = document.createElement('div');
    let panelDotsL = document.createElement('div');
    let panelDotsR = document.createElement('div');

    _u.addClass('panel panel--border-red', panel);
    _u.addClass('panel panel--bg-white', panel);
    _u.addClass('panel__dots', panelDotsL);
    _u.addClass('panel__dots--left', panelDotsL);
    _u.addClass('panel__dots', panelDotsR);
    _u.addClass('panel__dots--right', panelDotsR);

    // modal.modalClose.setAttribute('tabindex', 0); 

    // setting up an invisible element to control focus loop, at the END of the modal.
    // putting it within in this if statement so it doesn't run more than once.
    if (!modal.lastFocus) {
      let lastFocus = document.createElement('div'),
        modalWrapper = modal.modalWrapper;

      _u.addClass('button-focus-after', lastFocus);
      lastFocus.setAttribute('tabindex', 0);
      lastFocus.addEventListener('focus', () => {
        modal.modalClose.focus();
      });
      modalWrapper.insertAdjacentElement('beforeend', lastFocus);
      modal.lastFocus = lastFocus;
    }

    let retailerElm = new nclood.Retailers({
      theme: "light",
      productId: this.productId,
      platform: this.platform,
      country: this.country,
      hide: this.hide
    });

    panel.appendChild(panelDotsL);
    panel.appendChild(panelDotsR);
    panel.appendChild(retailerElm);

    Modal.onOpen = ()=>{
      setTimeout(()=>{
        _u.addClass('js-animate', Modal.instance.modalContent);

        // this is a workaround for hiding a retailer. the nclood 'hide' option is not working:
        setTimeout(() => {
          if (this.country == 'ca') {
            let retailerLinks = Modal.instance.modalContent.querySelector('.retailer-links');
            let walmart = Modal.instance.modalContent.querySelector('a[title="Walmart"]');
            if (retailerLinks && walmart) {
              let walmartParent = walmart.closest('li');
              retailerLinks.removeChild(walmartParent);
            }
            Modal.instance.modalContent.classList.add('modal__content--ca');
          }
        }, 200);

      },10);
      Modal.instance.modalClose.focus();
      console.log(this.productId);
    }

    Modal.onClose = ()=>{
      _u.removeClass('js-animate', Modal.instance.modalContent);
      Modal.instance.modalContent.classList.remove('modal__content--ca');

      // return user focus to a hidden element placed juuuust before the button that triggered the modal:
      document.querySelector('.flag-item .button-alt-focus').focus();
    }

    Modal.openRetailer(panel);

  }
}

ExecuteControllers.registerController(RetailerButton, 'RetailerButton');

export default RetailerButton;
