import _u from 'wtc-utility-helpers';
import Modal from 'wtc-modal-view';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import CONFIG from '../config';

class VideoButton extends ElementController {
  constructor (element) {
    super(element);

    this.siteWrapper = document.querySelector('.site-smm2');
    this.hash = this.element.getAttribute('data-video-hash');
    this.id = this.element.getAttribute('data-video');
    this.element.removeAttribute('data-video');

    let hash = Modal.hash;
    if (hash && hash == this.hash && this.id) {
      window.location.hash = '';
      setTimeout(()=>{
        this.open();
      },200);
    }

    try {
      nclood.mount('nclood-video')
    }
    catch (err) {
      if (CONFIG.CONST.DEVMODE)
        console.warn(err);
    }

    this.element.addEventListener('click', (evt) => {
      this.open();
    });
  }

  open () {
    setTimeout(() => {
      this.openVideo({
        videoId: this.id,
        hash: this.hash
      });
    }, 100);
  }

  openVideo(options = {}, ratio = 0.85, aspect = 16 / 9) {
    const modal = Modal.instance;
    let contentWrapper = modal.modalContent;
    let modalWrapper = modal.modalWrapper;
    let panel = document.createElement('div');
    let panelDotsL = document.createElement('div');
    let panelDotsR = document.createElement('div');

    _u.addClass('panel panel--border-white', panel);
    _u.addClass('panel__dots', panelDotsL);
    _u.addClass('panel__dots--left', panelDotsL);
    _u.addClass('panel__dots', panelDotsR);
    _u.addClass('panel__dots--right', panelDotsR);

    // setting up an invisible element to control focus loop, at the END of the modal.
    // putting it within in this if statement so it doesn't run more than once.
    if (!modal.lastFocus) {
      let lastFocus = document.createElement('div'),
        modalWrapper = modal.modalWrapper;

      _u.addClass('button-focus-after', lastFocus);
      lastFocus.setAttribute('tabindex', 0);
      lastFocus.addEventListener('focus', () => {
        modal.modalClose.focus();
      });
      modalWrapper.insertAdjacentElement('beforeend', lastFocus);
      modal.lastFocus = lastFocus;
    }

    let ncloodElm = document.createElement('div');
    panel.appendChild(ncloodElm);
    panel.appendChild(panelDotsL);
    panel.appendChild(panelDotsR);
    contentWrapper.appendChild(panel);

    let cs = getComputedStyle(panel);
    let paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    let borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    let elementWidth = panel.offsetWidth - paddingX - borderX;


    let width = elementWidth;
    let maxheight = window.innerHeight * ratio;

    if (width > document.body.clientWidth * ratio) {
      width = document.body.clientWidth * ratio;
    }

    let height = width / aspect;

    if (maxheight + 100 > window.innerHeight) {
      maxheight = maxheight - 80;
    }

    if (height > maxheight) {
      height = maxheight;
      width = maxheight * aspect;
    }


    let settings = _u.extend({
      target: ncloodElm,
      videoId: null,
      name: '',
      autoplay: true,
      width: width,
      height: height,
      initialBitrate: {
        level: 1,
        duration: 30
      },
      chromeless: false,
      muted: false
    }, options);

    var video = new nclood.Video(settings);

    if (options.hash) {
      window.location.hash = `!/${options.hash}/`;
    }
    Modal.onOpen = ()=>{
      setTimeout(()=>{
        _u.addClass('js-animate', Modal.instance.modalContent);
      },10);

      Modal.instance.modalClose.focus();

      // pause all other autoplaying videos in the background:
      _u.forEachNode(document.querySelectorAll('.autoplay-video__video'), (i, vid) => {
        if (!vid.paused) {
          vid.pause();
        }
      });

      // prevent scrolling, because that'll trigger autoplay videos to play (viewport class extension)
      _u.addClass('no-scroll', document.body);

      let reduceMotionPrefs = localStorage.getItem('prefersReducedMotion');
      Modal.instance.motionSetting = reduceMotionPrefs;
    }

    Modal.onClose = ()=>{
      _u.removeClass('js-animate', Modal.instance.modalContent);
      
      // return user focus to a hidden element placed juuust after the button that triggered the modal:
      this.element.parentNode.querySelector('.button-alt-focus').focus();

      if (Modal.instance.motionSetting === 'false') {
        // resume other autoplaying videos in the background
        _u.forEachNode(document.querySelectorAll('.autoplay-video__video'), (i, vid) => {
          if (vid.paused) {
            vid.play();
          }
        });

        // _u.removeClass('is-reduced-motion', this.siteWrapper);
      }

      _u.removeClass('no-scroll', document.body);

    }

    Modal.open(null, 'modal--video');
  }
}

ExecuteControllers.registerController(VideoButton, 'VideoButton');

export default VideoButton;
