/**
 * WTC <-> Nintendo Tracking
 * Simple class that abstracts some of the nclood library methods for easy and
 * standardized internal usage.
 * For more information on data-attributes usage, please read:
 * http://share.nintendo.com/nclood/stable/docs/#Metrics
 *
 * Namespace: window.wtc.Nintendo
 * Requirements: 0
 * Usage:
 * Initiate the class with all the options :D
 * NS.Nintendo.Tracking.init(trackerProps);
 *
 * Tracking Pages - Omniture.
 * trackPages: true.
 * Just set the option trackPages to true.
 *
 * Tracking Pages - Sizmek
 * trackPageSizmek: true
 * sizmekMainElement: 'body'
 * sizmekPageAttribute: 'data-page-sizmek'
 * The default is a 'data-page-sizmek' attribute on the 'body', but you can
 * change to anything you like.
 *
 * NOTE: THERE'S NO NEED TO CALL NS.Nintendo.TrackPage() if these options
 * are set. The class will automatica call the necessary tracking functions.
 *
 * Options:
 *
 */

import _u from 'wtc-utility-helpers';

let instance = null;

class Tracking {
  constructor(options) {
    if (instance) {
      return instance;
    }

    this.initiated = false;
    this.settings = _u.extend({
      trackerProps: {
        suite: 'dev',
        siteName: '',
        pageName: '',
        siteType: 'microsite',
        platform: '',
        root: '/'
      },

      pageTrackOpts: {
        productCode: '',
        vanity: undefined,
        productName: undefined,
        contentType: undefined,
        locale: undefined,
        productId: '',
        gameTitle: '',
        genre: '',
        publisher: '',
        eVars: {},
        events: [],
        isHome: false
      },

      language: 'EN',
      devMode: false,

      trackPages: true,

      trackAttribute: 'data-track',
      trackPageSizmek: true,
      sizmekMainElement: 'body',
      sizmekPageAttribute: 'data-page-sizmek'
    }, options);

    if (this.settings.devMode) {
      _u.log('warning', "Tracking instanciated");
    }

    instance = this;

    return this;
  }

  static get instance() {
    return instance;
  }

  static track(opts, event) {
    try {
      if (event && event.target.getAttribute('target') == '_blank') {
        nclood.Metrics.trackExitLink(opts, event);
      } else {
        nclood.Metrics.trackLink(opts);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  static init(options) {
    let tracking = Tracking.instance;

    if (!tracking) {
      tracking = new Tracking(options);
    }

    if(nclood && !instance.initiated) {
      instance.initiated = true;

      if (instance.settings.devMode) {
        _u.log('warning', "Tracking settings are ok.");
        _u.log('info', instance.settings);
      }

      nclood.Metrics.use(instance.settings.trackerProps);

      if (instance.settings.trackPages) {
        if (instance.settings.devMode) {
          _u.log('info', "Tracking Pages");
        }

        instance.trackPage();
      }

      // if (instance.settings.trackPageSizmek) {
      //   if (instance.settings.devMode) {
      //     _u.log('info', "Tracking Page Sizmek");
      //   }

      //   instance.trackPageSizmek();
      // }

      instance.setUpLinks();
    } else {
      if (instance.settings.devMode) {
        _u.log('error', 'Could not find nclood library.');
        return null;
      }
    }
  }

  trackPage (properties) {
    if(!properties || typeof propterties != 'string') {
      properties = {};
    }

    let opts = _u.deepExtend({}, this.settings.pageTrackOpts, properties);

    try {
      nclood.Metrics.trackPage(opts);
      return true;
    } catch (e) {
      if (this.settings.devMode) {
        _u.log('error', e.message);
      }
      return false;
    }
  }

  trackPageSizmek () {
    var id = document.querySelector(this.settings.sizmekMainElement).getAttribute(this.settings.sizmekPageAttribute);

    if (id !== 'undefined') {
      return this.trackSizmek(id);
    } else {
      if (this.settings.devMode) {
        return _u.log('error', "Page's Sizmek ID couldn't be found. Element: " + this.settings.sizmekMainElement + " | Attribute: " + this.settings.sizmekPageAttribute);
      }
    }
  }

  trackSizmek(id) {
    if(!this.initiated) {
      if (this.settings.devMode) {
        _u.log('error', 'Class not initiated.');
      }
      return false;
    }

    try {
      var sizmek_url = 'HTTPS://bs.serving-sys.com/Serving/ActivityServer.bs?';
      sizmek_url += 'cn=as&ActivityID=' + id + '&ns=1';

      var px = new Image(1, 1);
      px.src = sizmek_url;

      if (this.settings.devMode) {
        _u.log('success', 'Track Sizmek: ' + id);
      }

      return true;
    } catch (e) {
      if (this.settings.devMode) {
        _u.log('error', e.message);
      }

      return false;
    }
  }

  setUpLinks() {
    _u.forEachNode(document.querySelectorAll(`[${this.settings.trackAttribute}]`), (index, element)=>{
      var el = element;
      var track = element.getAttribute(this.settings.trackAttribute);

      el.removeAttribute(this.settings.trackAttribute);
      el.addEventListener('click', (e)=> {
        if (this.settings.devMode) {
          _u.log('info', 'Started');
        }

        var dealers = track.split(this.settings.trackDataDelimiter);
        for (var j = 0; j < dealers.length; j++) {
          var dealer = dealers[j];

          // if (dealer === 'sizmek') {
          //   this.trackSizmek(el.getAttribute('data-' + dealer));
          // } else {
          //   if (this.settings.devMode) {
          //     _u.log('error', 'Unrecognized tracking property: ' + dealer);
          //   }
          //   return false;
          // }
        }
      });
    });

    if (this.settings.devMode) {
      _u.log('info', "Links are ready for tracking");
    }
  }
}

export default Tracking;
