// Browser Support
import Matches from './App/Polyfills/Matches';
import Closest from './App/Polyfills/Closest';

// Base
import CONFIG from './App/config';
import {ExecuteControllers} from 'wtc-controller-element';

// Single Controllers
import Viewport from 'wtc-controller-viewports';
import PageControls from './App/Controllers/PageControls';
import Nav from './App/Controllers/Nav';
import AutoplayVideo from './Libs/AutoplayVideo';
import SwitcherStandard from './App/Controllers/SwitcherStandard';
import SwitcherHorizontal from './App/Controllers/SwitcherHorizontal';
import Carousel from './App/Controllers/Carousel';
import Tracking from './App/Nintendo/Tracking';
import VideoButton from './App/Nintendo/VideoButton';
import VideoEmbed from './App/Controllers/VideoEmbed';

// Page Controllers
import Home from './App/Pages/Home';
import Make from './App/Pages/Make';
import Play from './App/Pages/Play';
import Buy from './App/Pages/Buy';
import News from './App/Pages/News';

// prevent focus styles from showing up on mouse clicks:
document.body.addEventListener('mousedown', (e) => {
  let targ = e.target.closest('a') || e.target.closest('button') || e.target.closest('[tabindex]');
  if (targ) {
    setTimeout(() => {
      targ.blur()
    }, 0);
  }
}, true);

// Auto update footer copyright year
document.querySelector('.footer-main .cpdate').html = new Date().getFullYear();

// Start tracking.
Tracking.init(CONFIG.CONST.TRACKEROPTS);

// Instanciate all controllers
ExecuteControllers.instanciateAll();
