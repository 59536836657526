import _u from 'wtc-utility-helpers';
import Breakpoints from 'wtc-utility-breakpoint';
import { default as ElementController, ExecuteControllers } from 'wtc-controller-element';

class PageControls extends ElementController {
  constructor(element) {
    super(element);
    
    this.siteWrapper = document.querySelector('.site-smm2');
    this.reduceMotionBtn = element.querySelector('.reduce-motion');
    this.skipBtns = element.querySelectorAll('.skip');
    this.dataKey = 'prefersReducedMotion';

    // bind event callbacks
    this.setMotionPreference = this.setMotionPreference.bind(this);
    this.skip = this.skip.bind(this);
  
    // add event listeners
    this.reduceMotionBtn.addEventListener('click', this.setMotionPreference);
    _u.forEachNode(this.skipBtns, (i, btn) => {
      btn.addEventListener('click', this.skip);
    });
  
    // set preference based on any existing info we have
    this.setMotionPreference();
  }

  getMotionPreference() {
    return localStorage.getItem(this.dataKey);
  }

  setMotionPreference(e) {
    let wrap = this.siteWrapper;
    let preference = this.getMotionPreference();

    // if the change was triggered by an event...
    if (!!e && !!preference) {
      // !!preference will always return true, as it's a string
      if (preference === 'true') {
        localStorage.setItem(this.dataKey, 'false');
        _u.removeClass('is-reduced-motion', wrap);
        _u.removeClass('global-reduced-motion', document.body);
      } else {
        localStorage.setItem(this.dataKey, 'true');
        _u.addClass('is-reduced-motion', wrap);
        _u.addClass('global-reduced-motion', document.body);
      }

    } else {
      // if no event happened (i.e. on instantiation)
      let osReduced = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
      
      // first check for OS-level reduced motion settings
      if (osReduced) {

        localStorage.setItem(this.dataKey, 'true');
        _u.addClass('is-reduced-motion os-reduced-motion', wrap);
        _u.addClass('global-reduced-motion global-os-reduced-motion', document.body);

      } else {
        // then carry on as usual, querying localStorage for an existing motion preference
        switch (preference) {
          case null:
            localStorage.setItem(this.dataKey, 'false');
            _u.removeClass('is-reduced-motion', wrap);
            _u.removeClass('global-reduced-motion', document.body);
          break;
  
          case 'false':
            _u.removeClass('is-reduced-motion', wrap);
            _u.removeClass('global-reduced-motion', document.body);
          break;
  
          default:
            _u.addClass('is-reduced-motion', wrap);
            _u.addClass('global-reduced-motion', document.body);
          break;
        }

      }
    }

    // fire an event whenever the preference changes
    let event;
    let setting = this.getMotionPreference();
    if (window.CustomEvent) {
      event = new CustomEvent('motionprefchange', {detail: {pref: setting}});
    } else {
      event = document.createEvent('CustomEvent');
      event.initCustomEvent('motionprefchange', true, true, {pref: setting});
    }
    
    document.body.dispatchEvent(event);
  }

  skip(e) {
    let targ = e.currentTarget.getAttribute('data-scroll-to'),
      contentStart = document.querySelector(targ),
      yPos = _u.getElementPosition(contentStart).top - 118,
      targetFirstFocusable = contentStart.querySelector('[href], [tabindex], button'),
      duration = 500;

    this.scrollTo(yPos, duration)
    setTimeout(() => targetFirstFocusable.focus(), duration)
  }

  scrollTo(endY, duration) {
    let startY = this.scrollY,
      distanceY = endY - startY,
      fps = 1000/60, //60fps
      startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;
    // Easing function
    let easeInOutQuart = function (time, from, distance, duration) {
      if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
      return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function(){
      let time = new Date().getTime() - startTime,
        newY = easeInOutQuart(time,startY,distanceY,duration);
      if(time >= duration) {
        window.clearInterval(timer);
      }
      window.scrollTo(0, newY);
    },fps);
  }

  get scrollY() {
    let doc = document.documentElement;
    return (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
  }
}

ExecuteControllers.registerController(PageControls, 'PageControls');

export default PageControls;
