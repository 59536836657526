/**
 * Checks if the nclood library has loaded.
 */
 export const ncloodLoaded = async () => {
    let timer = null;
  
    try {
      await new Promise((resolve, reject) => {
        const startTime = Date.now();
        const checkLoop = () => {
          if (!window.nclood) {
            const now = Date.now();
            // If nclood is not loaded within 10 seconds, this will reject.
            if ((now - startTime) / 1000 > 10) {
              reject("nclood library failed to load.");
            } else {
              clearTimeout(timer);
              timer = setTimeout(() => {
                checkLoop();
              }, 500);
            }
          } else resolve(true);
        };
  
        checkLoop();
      });
  
      return !!window.nclood;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  
  /**
   * Queries the nclood object for user account information.
   * This will most often be used after awaiting ncloodLoaded();
   */
  export const getUser = async () => {
    await nclood.Accounts.whenReady;
    return nclood.Accounts.currentUser;
  };
  
  /**
   * Generates an image element (PNG) of the My Nintendo user's Mii.
   * If the user doesn't have a Mii, a generic fallback image will be used.
   * @return {HTMLCanvasElement}
   */
  export const getMii = () => {
    return new Promise((resolve, reject) => {
      window.Alps.Api.retrieveUserData((error, result) => {
        let miiIcon;
        let loadTimer;
  
        if (error) {
          console.log(error);
          reject(error);
        } else {
          miiIcon = result.getMiiIconUrl({
            width: 512,
          });
  
          if (!miiIcon) {
            miiIcon = `${SWEET_POTATO_RELATIVE_ROOT}/assets/images/generic-mii.png`;
          }
  
          loadTimer = setTimeout(() => reject(), 10000);
  
          const img = new Image();
  
          img.crossOrigin = "Anonymous";
  
          img.addEventListener("load", () => {
            clearTimeout(loadTimer);
            resolve(img);
          });
  
          img.onerror = () => {
            reject(new Error("Could not load Mii image."));
          };
  
          img.src = miiIcon;
        }
      });
    });
  };
  