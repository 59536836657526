import _u from 'wtc-utility-helpers';
import Breakpoints from 'wtc-utility-breakpoint';
import { default as ElementController, ExecuteControllers } from 'wtc-controller-element';

class Nav extends ElementController {
  constructor(element) {
    super(element);

    this._reducedMotion = localStorage.getItem('prefersReducedMotion') === 'true' ? true : false;
    this.ncomNav = document.querySelector('nclood-micro-nav');
    this.siteWrapper = document.querySelector('.site-smm2');
    this.menuButton = document.querySelector('.menu-button');
    this.currentBP = Breakpoints.current;

    // the following are hidden, focusable elements, to aid with keyboard focus:
    this.menuButtonFocusHelper = document.querySelector('.menu-button .invisible-focus');
    this.menuButtonReverseFocusHelper = document.querySelector('.site-nav__container .invisible-focus');
    this.navFocusHelper = document.querySelector('.nav-list-wrap .invisible-focus');

    // bind event callbacks
    this.handleMotionPrefsChange = this.handleMotionPrefsChange.bind(this);
    this.focusNav = this.focusNav.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.scroll = this.scroll.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.loopFocus = this.loopFocus.bind(this);

    // add event listeners
    document.body.addEventListener('motionprefchange', this.handleMotionPrefsChange);
    this.menuButton.addEventListener('click', this.toggleMenu);
    this.menuButtonFocusHelper.addEventListener('focus', this.focusNav);
    this.menuButtonReverseFocusHelper.addEventListener('focus', this.loopFocus);
    this.navFocusHelper.addEventListener('focus', this.loopFocus);
    window.addEventListener('keydown', this.toggleMenu);
    window.addEventListener('scroll', this.scroll);
    window.addEventListener('resize', this.resizeHandler);

    this.scroll();
  }

  get minTop() {
    return _u.getElementPosition(this.element).top + this.element.clientHeight;
  }
  get minTopIncludeSiteNav() {
    return _u.getElementPosition(this.element).top + this.element.querySelector('.site-nav__wrapper').clientHeight + 70;
  }

  handleMotionPrefsChange(e) {
    this.reducedMotion = e.detail.pref === 'true' ? true : false;
  }

  resizeHandler() {
    if (Breakpoints.current != this.currentBP) {
      this.currentBP = Breakpoints.current;
    }
  }

  scroll() {
    if (this.scrollY > this.minTopIncludeSiteNav) {
      if (!this.siteWrapper.classList.contains('js-is-passed-nav')) {
        this.siteWrapper.classList.add('js-is-passed-nav');
      }
    } else {
      if (this.siteWrapper.classList.contains('js-is-passed-nav')) {
        this.siteWrapper.classList.remove('js-is-passed-nav');
      }
    }

    if (this.currentBP == 1 && this.scrollY > 145) {
      if (!this.siteWrapper.classList.contains('fix-menu-btn')) {
        this.siteWrapper.classList.add('fix-menu-btn');
      }
    } else if (this.currentBP == 1 && this.scrollY <= 145) {
      if (this.siteWrapper.classList.contains('fix-menu-btn')) {
        this.siteWrapper.classList.remove('fix-menu-btn');
      }
    }
  }

  scrollTo(endY, duration) {
    let startY = this.scrollY,
      distanceY = endY - startY,
      fps = 1000/60, //60fps
      startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;
    // Easing function
    let easeInOutQuart = function (time, from, distance, duration) {
      if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
      return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function(){
      let time = new Date().getTime() - startTime,
        newY = easeInOutQuart(time,startY,distanceY,duration);
      if(time >= duration) {
        window.clearInterval(timer);
      }
      window.scrollTo(0, newY);
    },fps);
  }

  get scrollY() {
    let doc = document.documentElement;
    return (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
  }

  toggleMenu(e) {
    let isOpen = _u.hasClass('is-open', this.element);
    let label = isOpen ? 'Open main navigation.' : 'Close main navigation';

    if (e.type == 'keydown') {
      if (isOpen && e.which == 27) {
        _u.removeClass('is-open', this.element);
        this.menuButton.setAttribute('aria-expanded', !isOpen);
        this.menuButton.setAttribute('aria-label', label);
      }
    } else {
      if (isOpen) {
        _u.removeClass('is-open', this.element);
      } else {
        _u.addClass('is-open', this.element);
      }
      this.menuButton.setAttribute('aria-expanded', !isOpen);
      this.menuButton.setAttribute('aria-label', label);
    }
  }

  loopFocus() {
    this.menuButton.focus();
  }

  // Helps manage mobile menu "focus loop".
  focusNav() {
    let firstNavItem = this.element.querySelector('.nav__link');
    firstNavItem.focus();
  }

  get reducedMotion() {
    return this._reducedMotion;
  }
  set reducedMotion(value) {
    if (typeof value == 'boolean') this._reducedMotion = value;
  }

}

ExecuteControllers.registerController(Nav, 'Nav');

export default Nav;
