import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Gallery from 'wtc-gallery-component';

class SwitcherHorizontal extends Gallery {
  constructor (element, options = {}, isModal) {
    super(element);
    
    this._reducedMotion = localStorage.getItem('prefersReducedMotion') === 'true' ? true : false;
    this.trackingName = element.dataset.trackingName || null;
    this.vpAnimWrap = element.querySelector('.switcher--horizontal__wrap');
    this.itemWidth = Math.round(parseFloat(window.getComputedStyle(this.items[0]).flexBasis));
    this.itemMargin = Math.round(parseFloat(window.getComputedStyle(this.items[0]).marginRight));
    this.totalWidth = 0;
    this.xPos = this.itemWidth * -.5;
    this.isModal = isModal != undefined ? true : false;

    this.handleMotionPrefsChange = this.handleMotionPrefsChange.bind(this);
    this.options.onHasChanged = this.onHasChanged.bind(this);
    this.onResize = this.onResize.bind(this);
    this.manageVideoState = this.manageVideoState.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    
    // must wait for window load before we add the transitionEnd listener.
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.startTransitionListener = this.startTransitionListener.bind(this);

    document.body.addEventListener('motionprefchange', this.handleMotionPrefsChange);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('load', this.startTransitionListener);

    // start us up:
    this.onResize();

    // override some nav button stuff:
    this.nextBtn.innerHTML = '<span class="gallery__nav-inner"></span>';
    this.nextBtn.setAttribute('aria-label', 'Next carousel item.');
    this.prevBtn.innerHTML = '<span class="gallery__nav-inner"></span>';
    this.prevBtn.setAttribute('aria-label', 'Previous carousel item.');

    if (this.trackingName) 
      this.nextBtn.setAttribute('data-metric', `{ eVars:{8:'${this.trackingName}: arrow click'}, events:[11] }`);
      this.prevBtn.setAttribute('data-metric', `{ eVars:{8:'${this.trackingName}: arrow click'}, events:[11] }`);

    // some pre-requisites for any videos:
    _u.forEachNode(this.items, (i, item) => {
      let video = item.querySelector('.switcher-video');
      if (video) {
        video.muted = true;
        video.setAttribute('playsinline', '');
        video.setAttribute('muted', '');
      }
    });

    // Autoplay videos when in viewport
    if (!!window.IntersectionObserver && !this.isModal) {
      const switcherObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.reducedMotion) {
            this.manageVideoState(this.currentItem);
          } else {
            this.currentItem.querySelector('video').pause();
          }
        });
      }, { root: null, rootMargin: '0px 0px 0px 0px', threshold: 0 });
  
      setTimeout(() => switcherObserver.observe(element), 100);
    }
    else if (this.isModal && !this.reducedMotion) {
      this.startTransitionListener();
      this.manageVideoState(this.currentItem);
    }
  }

  handleMotionPrefsChange(e) {
    this.reducedMotion = e.detail.pref === 'true' ? true : false;

    if (this.reducedMotion) {
      this.wrapper.removeEventListener('transitionend', this.onTransitionEnd);
    } else {
      this.wrapper.addEventListener('transitionend', this.onTransitionEnd);
    }
  }

  startTransitionListener() {
    if (!this.reducedMotion) {
      this.wrapper.addEventListener('transitionend', this.onTransitionEnd);
    }
  }

  positionItem(activeItem) {
    let index = +activeItem.dataset.index;
    if (index === 0) this.xPos = this.itemWidth * .5;
    else this.xPos = this.itemWidth * (index + .5) + this.itemMargin * index;
    this.wrapper.style.transform = `translateX(-${this.xPos}px)`;
  }

  manageVideoState(activeItem) {
    let video = activeItem.querySelector('.switcher-video__video');
    if (!this.reducedMotion) {
      this.playVideo(video);
      if (video.paused) video.play(); // force iOS to play it
    }
  }

  playVideo(videoElement) {
    if (videoElement.readyState >= 2) {
      if (videoElement.paused) {
        let testPlay = videoElement.play();

        try {
          if (typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1 && testPlay && testPlay instanceof Promise) {
            testPlay.then(videoElement.play());
          }
          else if (!videoElement.paused) {
            videoElement.play();
          }
          else {
            videoElement.parentNode.classList.add('is-frozen');
          }
        }
        catch (error) {
          videoElement.parentNode.classList.add('is-frozen');
        }
      }
      else {
        videoElement.play();
      }
    }
    else {
      setTimeout(() => {
        this.playVideo(videoElement);
      }, 500);
    }
  }

  pauseVideo(videoElement) {
    videoElement.pause();
  }

  onHasChanged(nextItem, currentItem) {
    this.positionItem(nextItem);
  }

  onResize() {
    this.itemWidth = Math.round(parseFloat(window.getComputedStyle(this.items[0]).flexBasis));
    this.itemMargin = Math.round(parseFloat(window.getComputedStyle(this.items[0]).marginRight));
    this.totalWidth = this.itemWidth * this.items.length + (this.itemMargin * this.items.length - 1);
    this.wrapper.style.width = `${this.totalWidth}px`;
    this.vpAnimWrap.style.transform = `translateX(${(this.itemWidth + window.innerWidth) * .5}px)`;
    this.positionItem(this.currentItem);
  }

  onTransitionEnd(e) {
    if (e.target === this.wrapper) {
      _u.forEachNode(this.items, (i, item) => {
        let video = item.querySelector('.switcher-video__video');
        if (item === this.currentItem) {
          this.manageVideoState(item);
          if (video.paused) video.play();  // force iOS to play it
        } else {
          video.pause();
        }
      });
    }
  }

  get reducedMotion() {
    return this._reducedMotion;
  }
  set reducedMotion(value) {
    if (typeof value == 'boolean') this._reducedMotion = value;
  }
}

ExecuteControllers.registerController(SwitcherHorizontal, 'SwitcherHorizontal');

export default SwitcherHorizontal;