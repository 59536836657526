import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Breakpoints from 'wtc-utility-breakpoint';
import Viewport from 'wtc-controller-viewports';

class Home extends ElementController {
  constructor (element) {
    super(element);

    this.siteWrapper = element.querySelector('.site-smm2');
    this.ncomNav = element.querySelector('nclood-micro-nav');
    this.ncomHeight = this.ncomNav.offsetHeight;
    this.header = element.querySelector('header.header');
    this.scrollHint = element.querySelector('.scroll-hint');
    this.mainContentFocus = element.querySelector('.invisible-focus');
    this.debounceTimeout;
    this.currentBp = Breakpoints.current;

    // news CTA elements:
    this.news = {
      text: element.querySelector('.latest-news p'),
      readMore: element.querySelector('.latest-news .read-more')
    }

    this.skipIntro = this.skipIntro.bind(this);
    this.handleNewsCTALength = this.handleNewsCTALength.bind(this);
    this.onResize = this.onResize.bind(this);

    this.scrollHint.addEventListener('click', this.skipIntro);
    window.addEventListener('resize', this.onResize);

    if (this.currentBp > 2) {
      this.header.style.height = `${window.innerHeight - this.ncomHeight}px`;
    }

    this.siteWrapper.classList.add('trigger-intro-anims');
    this.handleNewsCTALength();
  }

  skipIntro() {
    this.scrollTo(window.innerHeight - 119, 500);
    this.mainContentFocus.focus();
  }

  scrollTo(endY, duration) {
    let startY = this.scrollY,
      distanceY = endY - startY,
      fps = 1000/60, //60fps
      startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;
    // Easing function
    let easeInOutQuart = function (time, from, distance, duration) {
      if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
      return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function(){
      let time = new Date().getTime() - startTime,
        newY = easeInOutQuart(time,startY,distanceY,duration);
      if(time >= duration) {
        window.clearInterval(timer);
      }
      window.scrollTo(0, newY);
    },fps);
  }

  handleNewsCTALength() {
    if (this.news.text) {
      if (this.news.text.textContent.length < 88 && this.currentBp > 2) {
        this.news.readMore.classList.remove('read-more--truncate');
      } else {
        this.news.readMore.classList.add('read-more--truncate');
      }
    }
  }

  onResize() {
    if (this.currentBp !== Breakpoints.current) {
      this.currentBp = Breakpoints.current;
    }

    if (this.currentBp > 2) {
      this.ncomHeight = this.ncomNav.offsetHeight;
      this.header.style.height = `${window.innerHeight - this.ncomHeight}px`;
    } else {
      this.header.removeAttribute('style');
    }

    // some debounced resize handling for the latest news CTA
    if (this.news.text) {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(this.handleNewsCTALength, 200);
    }
  }

  get scrollY() {
    let doc = document.documentElement;
    return (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
  }
}

ExecuteControllers.registerController(Home, 'Home');

export default Home;
