import {default as ElementController, ExecuteControllers} from 'wtc-controller-element';
import Modal from 'wtc-modal-view';
import SwitcherHorizontal from '../Controllers/SwitcherHorizontal';
import _u from 'wtc-utility-helpers';
import Viewport from 'wtc-controller-viewports';

class Make extends ElementController {
  constructor(element) {
    super(element);

    this.modalTriggers = [...element.querySelectorAll('.grid__modal-trigger')];
    this.cardHolders = element.querySelectorAll('.cards__item');
    this.flipDelay = 5000;
    this.activeCardIndex = 0;
    this.flipInterval = setInterval(() => {
      const event = new CustomEvent('cardflip', {detail: {activeIndex: this.activeCardIndex}});
      document.body.dispatchEvent(event);
      this.activeCardIndex = this.activeCardIndex == 4 ? 0 : this.activeCardIndex + 1;
    }, this.flipDelay);
    this.night = element.querySelector('.subsection--night');
    this.nightBg = this.night.querySelector('.darkness');
    this.nightVp = new Viewport(this.night, { animationCallback: this.nightCallback.bind(this) });

    this.handleModalTrigger = this.handleModalTrigger.bind(this);
    this.nightCallback = this.nightCallback.bind(this);
    this.handleFocusLoop = this.handleFocusLoop.bind(this);

    this.modalTriggers.forEach(trig => trig.addEventListener('click', this.handleModalTrigger));
  }

  nightCallback(topPercent) {
    if (topPercent < 1) {
      this.nightBg.style.opacity = topPercent;
    }
  }

  handleModalTrigger(e) {
    const gridItem = e.target.closest('.grid__modal-trigger'),
      itemIndex = this.modalTriggers.indexOf(gridItem);
    this.openModal(itemIndex, gridItem);
  }

  openModal(index, triggerElement) {
    const modal = Modal.instance,
      contentWrapper = document.createElement('div'),
      lastFocusable = document.createElement('span'),
      switcherEl = this.element.querySelector('.switcher--modal').cloneNode(true);

    let firstFocusable;

    lastFocusable.classList.add('visually-hidden');
    lastFocusable.setAttribute('tabindex', 0);
    contentWrapper.appendChild(switcherEl);
    contentWrapper.appendChild(lastFocusable);
    switcherEl.removeAttribute('hidden');

    // open the modal, and set focus to the beginning of its content
    Modal.open(contentWrapper, 'modal--switcher');
    firstFocusable = this.element.querySelector('.modal__close');
    firstFocusable.focus();
    
    // fire up our switcher
    const switcherInstance = new SwitcherHorizontal(switcherEl, { nav: true }, true);

    // handle showing and hiding of switcher buttons based on index:
    if (index) {
      switcherInstance.moveByIndex(index);
      
      if (index === switcherInstance.items.length - 1) {
        switcherInstance.prevBtn.removeAttribute('disabled');
      }
    }

    // if user reaches end of modal content, focus them back to the beginning
    lastFocusable.addEventListener('focus', this.handleFocusLoop);

    Modal.onClose = () => {
      lastFocusable.removeEventListener('focus', this.handleFocusLoop);
      if (triggerElement) triggerElement.focus();
    }
  }

  handleFocusLoop(e) {
    const modalFirstFocusable = this.element.querySelector('.modal__close');
    if (modalFirstFocusable) {
      modalFirstFocusable.focus();
    }
  }
}

ExecuteControllers.registerController(Make, 'Make');

export default Make;