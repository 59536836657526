import { default as ElementController, ExecuteControllers } from 'wtc-controller-element';

class VideoEmbed extends ElementController {
  constructor(element) {
    super(element);

    this.video = element;

    this.resizeVideo = this.resizeVideo.bind(this);
    window.addEventListener('resize', this.resizeVideo);

    this.resizeVideo();
  }

  resizeVideo() {
    let parent = this.video.parentNode,
      videoWidth = this.video.getAttribute('width'),
      videoHeight = this.video.getAttribute('height'),
      videoRatio = videoHeight / videoWidth,
      parentWidth = parent.offsetWidth,
      parentHeight = parentWidth * videoRatio;

    this.video.style.width = `${parentWidth}px`;
    this.video.style.height = `${parentHeight}px`;
  }

  get video() {
    return this._video;
  }
  set video(value) {
    this._video = value;
  }
}

ExecuteControllers.registerController(VideoEmbed, 'VideoEmbed');

export default VideoEmbed;
