import {default as ElementController, ExecuteControllers} from 'wtc-controller-element';

class News extends ElementController {
  constructor(element) {
    super(element);

    this.filters = element.querySelectorAll('.news-filter__button');
    this.newsItems = element.querySelectorAll('.news-item');
    this.categorized = {};
    this.btnActiveClass = 'news-filter__button--active';
    this.hiddenClass = 'news-item--hidden';
    this.trnsClassOff = 'transitioning-off';
    this.trnsClassOn = 'transitioning-on';
    this.everythingText = 'Filter <span class="visually-hidden">news articles</span>&nbsp;by everything';

    // bind event callbacks:
    this.handleButtonClick = this.handleButtonClick.bind(this);

    // add event listeners:
    this.filters.forEach(btn => {
      btn.addEventListener('click', this.handleButtonClick);
    });

    // set up collections of each category:
    this.newsItems.forEach(item => {
      let category = item.dataset.category;
      if (!this.categorized.hasOwnProperty(category)) {
        this.categorized[category] = [];
      }
      this.categorized[category].push(item);
    });

    this.activeFilter = null;
  }

  /**
   * Handles the click event when filters are used.
   * 
   * @param {Event} e The click event itself.
   */
  handleButtonClick(e) {
    let button = e.currentTarget;
    let category = button.dataset.filter;
    this.toggleButtonState(button);
    this.filterArticles(category);
  }

  /**
   * Adds transitionary and hidden classes to the items, based on the supplied filter string.
   * 
   * @param {string} filterBy Category of items to filter. This corresponds to the data-filter attribute on the item.
   */
  filterArticles(filterBy) {
    let current = this.activeFilter;

    for (let category of Object.keys(this.categorized)) {
      this.categorized[category].forEach(item => {
        // if either this.activeFilter is null, or it differs from filterBy…
        if (!current || current !== filterBy) {
          // …then hide all items that are not of the selected filter type,
          // and show all previously-filtered items.
          if (category !== filterBy) this.manageOffClasses(item);
          else this.manageOnClasses(item);
          this.activeFilter = filterBy;
        }
        // but if this.activeFilter is the the SAME as filterBy…
        else if (current === filterBy) {
          // un-toggle, by showing the previously-filtered items.
          if (category !== filterBy) this.manageOnClasses(item);
          this.activeFilter = null;
        }
      });
    }
  }

  /**
   * Helper for adding and removing classes when hiding filtered items.
   * 
   * @param {HTMLElement} element the element to add/remove classes to/from
   */
  manageOffClasses(element) {
    element.classList.add(this.trnsClassOff);
    element.querySelector('[href]').setAttribute('tabindex', -1);
    setTimeout(() => {
      element.classList.add(this.hiddenClass);
      element.classList.remove(this.trnsClassOff);
    }, 500);
  }

  /**
   * Helper for adding and removing classes when showing (un)filtered items.
   * 
   * @param {HTMLElement} element the element to add/remove classes to/from
   */
  manageOnClasses(element) {
    element.classList.remove(this.hiddenClass);
    element.classList.add(this.trnsClassOn);
    element.querySelector('[href]').removeAttribute('tabindex');
    setTimeout(() => {
      element.classList.remove(this.trnsClassOn);
    }, 500);
  }

  /**
   * Toggles the state of the filter buttons, via CSS classes.
   * 
   * @param {HTMLElement} element The element in the DOM to apply the class to.
   */
  toggleButtonState(element) {
    this.filters.forEach(btn => {
      if (btn === element) {
        if (element.classList.contains(this.btnActiveClass)) {
          element.classList.remove(this.btnActiveClass);
          element.innerHTML = element.dataset.text;
        } else {
          element.classList.add(this.btnActiveClass);
          element.innerHTML = this.everythingText;
        }
      }
      else {
        btn.classList.remove(this.btnActiveClass);
        btn.innerHTML = btn.dataset.text;
      }
    });
  }

  /**
   * Getter/setter - current category filter
   * @returns {string}
   */
  get activeFilter() {
    return this._activeFilter;
  }
  set activeFilter(value) {
    this._activeFilter = value;
  }
}

ExecuteControllers.registerController(News, 'News');

export default News;