import { default as ElementController, ExecuteControllers } from 'wtc-controller-element';
import Breakpoints from 'wtc-utility-breakpoint';

class Carousel extends ElementController {
  constructor(element) {
    super(element);
 
    this.reducedMotion = localStorage.getItem('prefersReducedMotion') === 'true' ? true : false;
    this.flips = element.dataset.flip ? true : false;
    this.el = element;
    this.container = this.el.querySelector('.carousel__container');
    this.children = [];
    for (let i = 0; i < this.container.children.length; i++) {
      this.children.push(this.container.children[i].cloneNode(true));
    }
    this.nextChild = 0;
    this.activeIndex = 4; // initial setting for the "card flipping"

    this.onResize = this.onResize.bind(this);
    this.run = this.run.bind(this);
    this.flipCards = this.flipCards.bind(this);
    this.handleMotionPrefsChange = this.handleMotionPrefsChange.bind(this);

    // attach event handlers
    if (this.flips) document.body.addEventListener('cardflip', this.flipCards);
    document.body.addEventListener('motionprefchange', this.handleMotionPrefsChange)

    // fill gaps, if any
    this.testSize();

    // setting up an IntersectionObserver to toggle on/off the animation, when in/out of the viewport:
    if (!!window.IntersectionObserver) {
      const carouselObserver = new IntersectionObserver((entries, self) => {
        if (entries[0].isIntersecting && !this.reducedMotion) {
          this.animating = true;
        } else {
          this.animating = false;
        }
      }, { root: null, rootMargin: '0px 0px 0px 0px', threshold: 0 });
  
      carouselObserver.observe(element);
    }
    else {
      // assuming that if the browser doesn't support Intersection Observer, it's not modern enough to handle this animation.
      this.animating = false;
    }
  }

  handleMotionPrefsChange(e) {
    this.reducedMotion = e.detail.pref === 'true' ? true : false;
  }

  testSize() {
    let addWidth = this.width - this.offset;
    if (this.childWidth < addWidth) {
      let breaker = 0;
      let existingChildren = this.container.children;
      let addedOffset = 0;
      let toRemove = [];

      // First we test if any items are now offscreen
      // We don't do this every frame because that would be burdensome
      // So we only do it when we know we have new items to add
      for (let i = 0; i < existingChildren.length; i++) {
        let child = existingChildren[i];
        let offsetLeft = child.offsetLeft + this.offset;
        let offsetWidth = child.offsetWidth;
        if (offsetLeft < -offsetWidth) {
          toRemove.push(child);
          addedOffset += offsetWidth;
        }
      }

      toRemove.forEach(child => {
        this.container.removeChild(child);
      });
      this.offset += addedOffset;
      addWidth -= addedOffset;

      // Then we loop, adding new children until we're at full width
      while (this.childWidth < addWidth) {
        const newNode = this.children[this.nextChild++].cloneNode(true);
        const holders = newNode.querySelectorAll('.cards__item');
        for (let holder of holders) {
          const cards = holder.querySelectorAll('.cards__img');
          cards.forEach((card, i) => {
            if (i == this.activeIndex) {
              card.classList.add('cards__img--active');
            } else {
              card.classList.remove('cards__img--active');
            }
          });
        }
        this.container.appendChild(newNode);

        // Instanciate any data controllers in the container
        for (var element of this.container.querySelectorAll("[data-controller]")) {

          if (!element.data || !element.data.instanciated) {
            ExecuteControllers.instanciate(
              element,
              element.getAttribute('data-controller')
            );
          }
        }
        breaker++;

        if (breaker > 100) break; // we should never have more than 100 items. If we do something is wrong.
      }
    }
  }

  flipCards(e) {
    this.activeIndex = e.detail.activeIndex;

    // go over all "pairs" of cards, then the cards themselves.
    for (let item of this.container.children) {
      const holders = item.querySelectorAll('.cards__item');
      for (let holder of holders) {
        const cards = holder.querySelectorAll('.cards__img');
        cards.forEach((card, i) => {
          if (i == this.activeIndex) {
            card.classList.add('cards__img--active');
          } else {
            card.classList.remove('cards__img--active');
          }
        });
      }
    };
  }

  onResize(e) {
    this.testSize();
  }

  run(delta) {
    if (!this.paused) {
      this.offset -= this.speed;
      this.testSize();
    }

    if (this.animating && !this.reducedMotion) {
      requestAnimationFrame(this.run);
    }
  }

  get childWidth() {
    let children = this.container.children;
    let width = 0;
    for (let i = 0; i < children.length; i++) {
      width += children[i].offsetWidth;
    }
    return width;
  }

  get width() {
    return this.el.offsetWidth;
  }

  set nextChild(value) {
    this._nextChild = value % this.children.length;
  }
  get nextChild() {
    return this._nextChild;
  }

  set speed(value) {
    if(value >= 0) {
      this._speed = value;
    }
  }
  get speed() {
    return this._speed || this.basicSpeed;
  }

  get basicSpeed() {
    return Breakpoints.current / 10 + 0.6;
  }

  set offset(value) {
    if (!isNaN(value)) {
      this._offset = value;
      this.container.style.transform = `translate3d(${value}px, 0, 1px)`;
    }
  }
  get offset() {
    return this._offset || 0;
  }

  set paused(value) {
    this._paused = value === true;
  }
  get paused() {
    return this._paused === true;
  }

  set animating(value) {
    if (value === true && this.animating !== true) {
      requestAnimationFrame(this.run);
    }
    this._animating = value === true;
  }
  get animating() {
    return this._animating === true;
  }

  get reducedMotion() {
    return this._reducedMotion;
  }
  set reducedMotion(value) {
    if (typeof value == 'boolean') this._reducedMotion = value;
  }
}

ExecuteControllers.registerController(Carousel, 'Carousel');

export default Carousel;
