import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Gallery from 'wtc-gallery-component';
import { loadavg } from 'os';

class SwitcherStandard extends Gallery {
  constructor (element, options = {}) {
    super(element);

    this.handleMotionPrefsChange = this.handleMotionPrefsChange.bind(this);
    this.manageVideoState = this.manageVideoState.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    this.ready = false;

    // must wait for window load before we add the transitionEnd listener.
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.startTransitionListener = this.startTransitionListener.bind(this);

    document.body.addEventListener('motionprefchange', this.handleMotionPrefsChange);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('load', this.startTransitionListener);

    // some pre-requisites for any videos:
    _u.forEachNode(this.items, (i, item) => {
      let video = item.querySelector('.switcher-video');
      if (video) {
        video.muted = true;
        video.setAttribute('playsinline', '');
        video.setAttribute('muted', '');
      }
    });

    // Autoplay videos when in viewport
    if (!!window.IntersectionObserver) {
      const switcherObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.reducedMotion) {
            this.manageVideoState(this.currentItem);
          } else {
            this.currentItem.querySelector('video').pause();
          }
        });
      }, { root: null, rootMargin: '0px 0px 0px 0px', threshold: 0 });
  
      setTimeout(() => switcherObserver.observe(element), 100);
    }
  }

  handleMotionPrefsChange(e) {
    this.reducedMotion = e.detail.pref === 'true' ? true : false;

    if (this.reducedMotion) {
      this.wrapper.removeEventListener('transitionend', this.onTransitionEnd);
    } else {
      this.wrapper.addEventListener('transitionend', this.onTransitionEnd);
    }
  }

  startTransitionListener() {
    this.resize();
    this.ready = true;

    if (!this.reducedMotion) {
      this.wrapper.addEventListener('transitionend', this.onTransitionEnd);
    }
  }

  manageVideoState(activeItem) {
    let video = activeItem.querySelector('.switcher-video__video');
    if (!this.reducedMotion) {
      this.playVideo(video);
      if (video.paused) video.play();  // force iOS to play it
    }
  }

  playVideo(videoElement) {
    if (videoElement.readyState >= 2) {
      if (videoElement.paused) {
        let testPlay = videoElement.play();

        try {
          if (typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1 && testPlay && testPlay instanceof Promise) {
            testPlay.then(videoElement.play());
          }
          else if (!videoElement.paused) {
            videoElement.play();
          }
          else {
            videoElement.parentNode.classList.add('is-frozen');
          }
        }
        catch (error) {
          videoElement.parentNode.classList.add('is-frozen');
        }
      }
      else {
        videoElement.play();
      }
    }
    else {
      setTimeout(() => {
        this.playVideo(videoElement);
      }, 500);
    }
  }

  pauseVideo(videoElement) {
    videoElement.pause();
  }

  onTransitionEnd(e) {
    if (this.ready) {
      if (e.target.classList.contains('is-active')) {
        _u.forEachNode(this.items, (i, item) => {
          let video = item.querySelector('.switcher-video__video');
          if (item === this.currentItem) {
            this.manageVideoState(item);
            if (video.paused) video.play();  // force iOS to play it
          } else {
            video.pause();
          }
        });
      }
    }
  }

  get reducedMotion() {
    return this._reducedMotion;
  }
  set reducedMotion(value) {
    if (typeof value == 'boolean') this._reducedMotion = value;
  }

}

ExecuteControllers.registerController(SwitcherStandard, 'SwitcherStandard');

export default SwitcherStandard;