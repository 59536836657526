import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import RetailerButton from '../Nintendo/RetailerButton';
import initWishlist from "../Nintendo/wishlist"

class Buy extends ElementController {
  constructor (element) {
    super(element);
    
    nclood.Accounts.ready(() => {
      if (nclood.Accounts.currentUser) {
         this.user = nclood.Accounts.currentUser;
        } 
        this.setupDigitalPrices();
   });
    // this.user = JSON.parse(localStorage.getItem('alps/userData'));
    if (window.location.href.includes("#retail")) {
      const wtbButton = document.querySelector('nclood-where-to-buy');
      const retailSection = document.querySelector('#retail');
      retailSection.scrollIntoView();
      wtbButton.addEventListener('loaded', () => {
        wtbButton.querySelector(".ps-widget").click();
      });
    }
  }

  setupDigitalPrices() {
    // Getting live prices for multiple products
    _u.forEachNode(document.querySelectorAll('[data-digitalprice]'), (index, item) => {
      let nsuid = item.getAttribute('data-nsuid');
      let country = item.getAttribute('data-country');
      let lang = item.getAttribute('data-lang') || 'en';

      if (this.user && lang == "en") {
        if (this.user.country.toLowerCase() === 'us' && country == "ca") {
          item.parentNode.classList.add('price--hide-ca');
          return;
        }
        if (this.user.country.toLowerCase() === 'ca' && country == "us") {
          item.parentNode.classList.add('price--hide-us');
          return;
        }
      }

      if (nsuid && nsuid.length > 0) {
        nclood.eShop.getPrices(nsuid, (error, prices) => {
          if (error) return console.log(error || 'Unable to get live prices');
          let priceInfo = prices[nsuid];
          let countrySel = country.toLowerCase();
          let price = '';

          if (priceInfo.status !== 'not_found' && priceInfo.status !== 'unreleased' ) {
            price = priceInfo.regular.raw;

            if (lang === "fr") {
              price = price.replace('.', ',');
            }

            // Updated US value
            let itemPrice = item.querySelector('.rawprice');
            itemPrice.innerText = price;

            item.className += " price--available";
          }
        }, { country: country, lang: lang, forceLoggedOut: (lang == "fr" ? true : false) });
      }
    });
    initWishlist();
  }
}

ExecuteControllers.registerController(Buy, 'Buy');

export default Buy;
